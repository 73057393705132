/**
 *
 * ParseUrl.ts
 *
 * Desc : Helper. to parse URL
 *
 * Author : Dmytro Teliakovskyi
 *
 * Project : cocolake
 *
 */


/**
 * function to parse url with a given name parameter
 * @param name
 * @param url
 */
export function parseUrlByName(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp(name + '(=([^&]*)|&|$)'),
        results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2]);
}