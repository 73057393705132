/**
 *
 * ResultController.ts
 *
 * Desc : Application. Controller to manage the result of research cv
 *
 * Author : Sebastien Hoarau, Dmytro Teliakovskyi, Nicolas Galois
 *
 * Project : cocolake
 *
 */


// Applications
import { getCandidate } from './CandidateController';

// Repositories
import { cvResultRepository } from '../infrastructure/api/CVResultRepository';

// Services
import { CVResultService } from '../domain/services/CVResultService';

// Models
import type { CVQuery } from '../../../models/CVQuery';
import type { CV } from '../../../models/CV';
import type { CVResult } from '../../../models/CVResult';
import type { TimeRange } from '../../../models/TimeRange';

// Helpers
import { parseUrlByName } from '../../../helpers/ParseUrl';
import { setVisitedCV } from './VisitedCVHelper';

// Stores
import { cvResultStore } from '../infrastructure/store/CVResultStore';


const resultService = new CVResultService(cvResultRepository);

/**
 * function to get id if it exist
 * @param url 
 * @returns 
 */
export function getIdSearch(url: any): string {
  let id: string = "notId";
  const potentialId = url.split('&').slice(-1)[0].split('=');
  if (potentialId.length && potentialId.length > 0) {
    if (potentialId[0] === "id") {
      id = potentialId[1];
    }
  }
  return id;
}

/**
 * function to get query from url
 * @param threshold
 * @param url
 */
function getQuery(threshold: number, url: any): CVQuery {
  const parsedKeywords = parseUrlByName('search', url);

  const keywordsReturn = parsedKeywords ? (parsedKeywords.split(' ') as string[]) : [''];
  const distance = parseUrlByName('distance', url) as unknown as number;
  const city = parseUrlByName('city', url) as string;
  const country = parseUrlByName('country', url) as string;
  const type = parseUrlByName('type', url) as string;

  let keywords: string[] = new Array<string>();
  if (keywordsReturn[0] !== 'undefined') {
    keywords = keywordsReturn;
  }

  return { threshold, keywords, distance, city, country, type };
}

/**
 * function to get cvs from url
 * and return currentCV which is the first one
 * @param threshold
 * @param url
 */
// TODO try to merge
export async function loadResult(threshold: number, url: any, timeRange: TimeRange) {
  const query: CVQuery = getQuery(threshold, url);

  try {
    // Get result from API
    const result = await resultService.get(query);

    // Check if we have CV
    if (result.cvs.length) {
      handleCVResult(result, timeRange);

      // Check if we display CV
      if (result.filteredCvs.length) {
        // Set current cv
        result.currentCV = result.cvs[0];

        // Call convert if docx
        if (result.currentCV.contentType !== 'application/pdf') {
          result.currentCV.data = await getCVDataById(result.currentCV.id);
        }
        
        setVisitedCV(result.cvs[0].id);
      } 
      
      // create store
      cvResultStore.set(result);
    }
  } catch (error) {
    //TODO see if it's ok
    //navigate("/err", {state: {message: }})
    throw error;
  }
}

/**
 * function to load more cvs from the same url
 * @param threshold
 * @param url
 */
export async function loadMoreCV(threshold: number, url: string, timeRange: TimeRange) {
  const query: CVQuery = getQuery(threshold, url);

  // If we have cvs in store we want to get the others from an other threshold
  const result = await resultService.get(query);
  await handleCVResult(result, timeRange);
  // Update store
  cvResultStore.update((currentResultStore) => {
    // Check if storage is not empty
    if (Object.keys(currentResultStore).length) {
      currentResultStore.cvs.push(...result.cvs);
      currentResultStore.filteredCvs.push(...result.filteredCvs);
    }
    return currentResultStore;
  });
}

/**
 * function to process cv result and filter cvs by selected time range
 * @param result results to process
 * @param timeRange time range to filter cvs by
 */
async function handleCVResult(result: CVResult, timeRange: TimeRange) {
  result.cvs.forEach(async cv => {
    let candidate = await getCandidate(cv.email, cv.id);

    if (candidate.contactId && candidate.career) {
      cv.isFoundOnCrm = true;
      cv.careerId = candidate.career.id;
    }
  })
  result.filteredCvs = filterCvsByTimeRange(result.cvs, timeRange);
}

/**
 * function to filter an array of cvs by a certain time range
 * @param cvs cvs array to filter
 * @param timeRange time range to filter cvs array by
 * @returns filtered cvs array
 */
function filterCvsByTimeRange(cvs: CV[], timeRange: TimeRange) {
  return cvs.filter(cv => {
    let date = new Date(cv.dateAdded);
    return timeRange !== undefined && date >= timeRange.endDate();
  });
}

/**
 * function to clear the cvs in the store, to ensure behaviour when multiple research
 */
export function clearCVs() {
  cvResultStore.reset();
}

/**
 * function to update cv view in store and set cv as visited by client
 * @param cv
 */
export async function changeCurrentCV(cv: CV) {
  if (cv.contentType !== 'application/pdf') {
    cv.data = await getCVDataById(cv.id);
  }

  // Saw by the client
  // set to session
  setVisitedCV(cv.id);

  // Update the result view
  cvResultStore.update((store: CVResult) => {
    return {
      nbResult: store.nbResult,
      cvs: store.cvs,
      filteredCvs: store.filteredCvs,
      currentCV: cv
    };
  });
}

/**
 * function to update time range to filter cvs by
 * @param timeRange time range to filter cvs by
 */
export function changeTimeRange(timeRange: TimeRange) {
  cvResultStore.update((store: CVResult) => {
    let newFilteredCvs = filterCvsByTimeRange(store.cvs, timeRange);
    return {
      nbResult: store.nbResult,
      cvs: store.cvs,
      filteredCvs: newFilteredCvs,
      currentCV: newFilteredCvs.includes(store.currentCV as CV) ? store.currentCV : undefined
    }
  });
}

/**
 * function to get cv data from id representing the md5 hash
 * @param id md5 hash of cv
 */
export async function getCVDataById(id: string): Promise<string> {
  try {
    return await resultService.getDataById(id);
  } catch (error) {
    throw error;
  }
}
