/**
  *
  * AutoCompleteController.ts
  *
  * Desc : Controller. controller to get autocomplete suggestion of city, country
  *
  * Author : Dmytro Teliakovskyi, Nicolas Galois
  *
  * Project : cocolake
  *
  */


// Repositories
import { autocompleteRepository } from '../infrastructure/api/AutocompleteRepository';

// Models
import type { AutoCompleteDisplayResult } from '../domain/types/AutoCompleteDisplayResult';
import { AutocompleteService } from '../domain/services/AutocompleteService';


const autocompleteService = new AutocompleteService(autocompleteRepository);

export async function find(keyword: string): Promise<AutoCompleteDisplayResult[]> {
  const suggestions = await autocompleteService.find(keyword);

  const autoCompleteDisplay = new Array(suggestions.length);
  
  for (let i = 0; i < suggestions.length; i++) {
    let label = suggestions[i].address.countryName;

    if(suggestions[i].address.city) {
      label = suggestions[i].address.city + ', ' + suggestions[i].address.countryName;
    }

    autoCompleteDisplay[i] = {
      label: label,
      city: suggestions[i].address.city,
      country: suggestions[i].address.countryName,
    };
  }

  return autoCompleteDisplay;
}
