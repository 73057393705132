/**
 *
 * CVResultService.ts
 *
 * Desc : Service. Service to get all cv of research
 *
 * Author : Sebastien Hoarau
 *
 * Project : cocolake
 *
 */


// Models
import type {CVResultRepositoryInterface} from '../interfaces/CVResultRepositoryInterface';
import type {CVResult} from "../../../../models/CVResult";
import type {CVQuery} from "../../../../models/CVQuery";


export class CVResultService {
    constructor(protected cvResultRepository: CVResultRepositoryInterface) {
    }

    /**
     * function to get CVS from a query
     * @param query
     */
    public async get(query: CVQuery): Promise<CVResult> {
        return this.cvResultRepository.get(query);
    }

    /**
     * function to get a data base64 of cv by ID
     * @param id
     */
    public async getDataById(id: string) {
        return await this.cvResultRepository.getDataById(id);
    }
}
 
