/**
 *
 * typesSearch.ts
 *
 * Desc : Constant. Types of search
 *
 * Author : Sebastien Hoarau
 *
 * Project : cocolake
 *
 */

export const typesSearch = [
    { label: "Names", value: "names" },
    { label: "Keywords", value: "keywords" }
]