/**
 *
 * SavedSearchService.ts
 *
 * Desc : Service. for saved searches from api
 *
 * Author : Nicolas Galois, Sebastien Hoarau
 *
 * Project : cocolake
 *
 */


// Models
import type {SavedSearchInterface} from "../interfaces/SavedSearchInterface";
import type {SavedSearch} from "../types/SavedSearch";


export class SavedSearchService {
    constructor(protected preferredUrlsRepository: SavedSearchInterface) {}

    /**
     * update if want notifications
     * @param email 
     * @param idSearch 
     * @param wantNotifications 
     * @returns 
     */
    async updateNotifications(email: string, idSearch: string, wantNotifications: boolean) : Promise<string> {
        return await this.preferredUrlsRepository.updateNotifications(email, idSearch, wantNotifications);
    }

    /**
     * update last cv id seen
     * @param email 
     * @param idSearch 
     * @param lastCVId 
     * @returns 
     */
    async update(email: string, idSearch: string, lastCVId: string) : Promise<string> {
        return await this.preferredUrlsRepository.update(email, idSearch, lastCVId);
    }

    /**
     * get all saved searches by email
     * @param email
     */
    async get(email: string) {
        return this.preferredUrlsRepository.get(email)
    }

    /**
     * add search to email
     * @param email
     * @param url
     */
    async add(email: string, url: SavedSearch): Promise<SavedSearch> {
        return this.preferredUrlsRepository.add(email, url)
    }

    /**
     * delete search to email
     * @param email
     * @param url
     */
    async delete(email: string, url: SavedSearch) {
        return this.preferredUrlsRepository.delete(email, url)
    }
}