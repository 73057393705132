<script lang="ts">
  /**
   *
   * InfiniteScroll.svelte
   *
   * Desc : Components. Infinite Scroll
   *
   * Author : Dmytro Teliakovskyi, Nicolas Galois
   *
   * Project : cocolake
   *
   */


  // Utils
  import { onDestroy, createEventDispatcher } from 'svelte';

  
  export let hasMore = true;

  const dispatch = createEventDispatcher();

  let threshold = 10;
  let isLoadMore = false;
  let component: HTMLDivElement;
  let parent: Node & ParentNode;

  $: if (component) {
    parent = component.parentNode!;
    parent.addEventListener('scroll', onScroll);
    parent.addEventListener('resize', onScroll);
  }

  onDestroy(() => {
    parent.removeEventListener('scroll', onScroll);
    parent.removeEventListener('resize', onScroll);
  });

  const onScroll = (e) => {
    const offset = e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop;
    if (offset <= threshold) {
      if (!isLoadMore && hasMore) {
        dispatch('loadMore');
      }
      isLoadMore = true;
    } else {
      isLoadMore = false;
    }
  };
</script>

<div class="spinner" bind:this={component}>
  {#if isLoadMore && hasMore}
    <div class="box"></div>
  {/if}
</div>

<style lang="scss">
  .spinner {
    display: flex;
    justify-content: center;
  }

  .box {
    margin-bottom: 4rem;
    width: 2rem;
    height: 2rem;
    background: #37f489;
    border-radius: 3px;
    animation: animate 0.5s linear infinite;
  }

  @keyframes animate {
    17% {
      border-bottom-right-radius: 3px;
    }
    25% {
      transform: translateY(9px) rotate(22.5deg);
    }
    50% {
      transform: translateY(18px) scale(1, 0.9) rotate(45deg);
      border-bottom-right-radius: 4rem;
    }
    75% {
      transform: translateY(9px) rotate(67.5deg);
    }
    100% {
      transform: translateY(0) rotate(90deg);
    }
  }
</style>
