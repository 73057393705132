/**
  *
  * UserService.ts
  *
  * Desc: Service. for microsoft authentication
  *
  * Author : Sebastien Hoarau
  *
  * Project : cocolake
  *
  */


// Models
import type { MsalRepositoryInterface } from '../interfaces/MsalRepositoryInterface';


export class UserService {
  constructor(protected msalRepository: MsalRepositoryInterface) {}

  async login() {
    await this.msalRepository.login();
  }

  getIdToken() {
    return this.msalRepository.getIdToken();
  }

  getUserName() {
    return this.msalRepository.getUserName();
  }

  getEmail() {
    return this.msalRepository.getEmail();
  }

  async logout() {
    await this.msalRepository.logout();
  }
}
