/**
 *
 * images.ts
 *
 * Desc : Constant. Images Bertek Url
 *
 * Author : Dmytro Teliakovskyi
 *
 * Project : cocolake
 *
 */


export const bertekLogo = `${global.APP_URL}/images/bertek.png`;
export const welcomeImage = `${global.APP_URL}/images/welcome.png`;