/**
 *
 * SavedSearchRepository.ts
 *
 * Desc : Repository. concrete implementation of savedSearch interface, used for saved search route
 *
 * Author : Nicolas Galois, Sebastien Hoarau
 *
 * Project : Cocolake
 *
 */


// Models
import type {SavedSearchInterface} from "../../domain/interfaces/SavedSearchInterface";
import type {SavedSearch} from "../../domain/types/SavedSearch";

// Helpers
import ApiRepository from "../../../../helpers/ApiRepository";


class SavedSearchRepository implements SavedSearchInterface {

    /**
     * update if you want notifications or not for a search
     * @param email 
     * @param idSearch 
     * @param wantNotifications 
     */
    async updateNotifications(email: string, idSearch: string, wantNotifications: boolean): Promise<string> {
        try {
            const { data } = await ApiRepository.post(`/savedsearch/notifications?email=${email}`, { idSearch: idSearch, wantNotifications: wantNotifications });
            return data;
        } catch (error) {
            throw error;
        }
    }

    /**
     * update the last CV id displayed for this search
     * @param email 
     * @param idSearch 
     * @param lastCVId 
     */
    async update(email: string, idSearch: string, lastCVId: string): Promise<string> {
        try {
            const { data } = await ApiRepository.post(`/savedsearch/lastcv?email=${email}`, { idSearch: idSearch, lastCVId: lastCVId });
            return data;
        } catch (error) {
            throw error;
        }
    }

    /**
     * add search for email
     * @param email
     * @param url
     */
    async add(email: string, url: SavedSearch): Promise<SavedSearch> {
        try {
            const {data} = await ApiRepository.post(`/savedsearch?email=${email}`, {search: url});
            return data;
        } catch (error) {
            throw error;
        }
    }

    /**
     * delete saved search from an email
     * @param email
     * @param url
     */
    async delete(email: string, url: SavedSearch): Promise<void> {
        try {
            const {data} = await ApiRepository.delete(`/savedsearch/${url.id}?email=${email}`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    /**
     * get all saved search by email
     * @param email
     */
    async get(email: string): Promise<SavedSearch[]> {
        try {
            const {data} = await ApiRepository.get(`/savedsearch?email=${email}`);
            return data;
        } catch (error) {
            throw error;
        }
    }
}

export const preferredRepository = new SavedSearchRepository()