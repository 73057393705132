/**
 *
 * CandidateService.ts
 *
 * Desc : Service. Service to get all Add Form fields of research
 *
 * Author : Dmytro Teliakovskyi, Nicolas Galois
 *
 * Project : cocolake
 *
 */


// Repositories
import { candidateFormRepository } from '../../infrastructure/api/CandidateRepositoty';

// Models
import type { CandidateRepositoryInterface } from '../interfaces/CandidateRepositoryInterface';
import type { CareerFields } from '../types/CareerFields';
import type {Candidate} from "../types/Candidate";


export class CandidateService {
  private addFormRepository: CandidateRepositoryInterface;

  constructor() {
    this.addFormRepository = candidateFormRepository;
  }

  async get(email: string, cvId: string): Promise<Candidate> {
    return this.addFormRepository.get(email, cvId)
  }

  /**
   * function to get Career fields from CRM
   *
   */
  public async getFields(): Promise<CareerFields> {
    return this.addFormRepository.getFields();
  }

  async create(candidate: Candidate, cvId: string, email: string, annotation: string) {
    return this.addFormRepository.create(candidate, cvId, email, annotation);
  }
}
