/**
 *
 * ResultHelper.ts
 *
 * Desc : Helper. Helper to convert in good format return
 *
 * Author : Sebastien Hoarau, Dmytro Teliakovskyi
 *
 * Project : cocolake
 *
 */


/**
 * function to get skills arranged in an array of string
 * @param languages
 * @param frameworks
 * @param tool
 * @param database
 */
export function getSkills(languages: string[], frameworks: string[], tool: string[], database: string[]): string[] {
  if (tool && tool.length && database && database.length) {
    return [languages[0], frameworks[0], database[0], tool[0]];
  }

  return [...languages.slice(0,2), ...frameworks.slice(0,2)];
}

/**
 * function to help display file name
 * @param fileName
 */
export function displayFileName(fileName) {
  const text = fileName.split('.')[0];
  return text.split('_').join(' ');
}

