<script>
  /**
   *
   * Modal.svelte
   *
   * Desc : Lib. Modal svelte
   *
   * Author : Dmytro Teliakovskyi
   *
   * Project : cocolake
   *
   */


  // Utils
  import { fade, fly } from "svelte/transition";
  import { quintOut } from "svelte/easing";

  
  export let open = false;

  let keyboardEvent;

  function attachEvent(target, ...args) {
    target.addEventListener(...args);
    return {
      remove: () => target.removeEventListener(...args),
    };
  }

  function handleBackdrop(event) {
    event.stopPropagation();
    open = false;
  }

  function onModalOpened(event) {
    keyboardEvent = attachEvent(document, "keydown", (event) => {
      if (event.key === "Escape") {
        open = false;
      }
    });
  }

  function onModalClosed() {
    if (keyboardEvent) {
      keyboardEvent.remove();
    }
  }
</script>

{#if open}
  <section
    transition:fade={{ duration: 150 }}
    on:click={() => (open = false)}
  />
  <main
    on:click|self={handleBackdrop}
    on:introend={onModalOpened}
    on:outroend={onModalClosed}
    transition:fade
  >
    <div
      in:fly={{ y: -50, duration: 300 }}
      out:fly={{ y: -50, duration: 300, easing: quintOut }}
    >
      <slot />
    </div>
  </main>
{/if}

<style lang="scss">
  section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.4);
  }
  main {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    opacity: 1;

    &:not(:focus-within) {
      transition: opacity 0.1ms;
      opacity: 0.99;
    }

    &:focus {
      outline: none;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fdfefe;
    z-index: 100;
    border-radius: 1rem;
    overflow: hidden;
  }
</style>
