/**
 *
 * SavedSearchController.ts
 *
 * Desc : Controller. controller for preferred urls of active user
 *
 * Author : Nicolas Galois, Sebastien Hoarau
 *
 * Project : cocolake
 *
 */


// Applications
import { getEmail } from "../../authentication/application/UserController";

// Repositories
import { preferredRepository } from "../infrastructure/api/SavedSearchRepository";

// Services
import { SavedSearchService } from "../domain/services/SavedSearchService";

// Stores
import { savedSearchStore } from "../infrastructure/store/SavedSearchStore";

// Models
import type { SavedSearch } from "../domain/types/SavedSearch";

// Utils
import { get } from "svelte/store";


const preferredService = new SavedSearchService(preferredRepository);

export default class SavedSearchController {

  // TODO error
  // TODO set store to update the store
  /**
   * update if recruiter want notifications or not for this search
   * @param idSearch 
   * @param wantNotifications 
   */
  static updateNotifications = async (idSearch: string, wantNotifications: boolean) => {
    const email = getEmail();

    try {
      const result = await preferredService.updateNotifications(email, idSearch, wantNotifications);
      if(result !== 'updated'){
        throw new Error("Not success to activate/deactive notifications");
      }
    } catch (error) {
      throw error;
    }
  }

  // TODO set store to update the store
  /**
   * update the last cv id of this search
   * @param idSearch 
   * @param lastCVId 
   */
  static update = async (idSearch: string, lastCVId: string) => {
    const email = getEmail();

    try {
      const result = await preferredService.update(email, idSearch, lastCVId);
      if(result === 'updated'){
        let search : SavedSearch = get(savedSearchStore).find(search => search.id === idSearch) as SavedSearch;

        search.lastCVId = lastCVId;
        search.nbNewResult = 0;
        const oldStore = get(savedSearchStore)
        const newStore = oldStore.filter(saved => {
          return saved.id !== idSearch
        });

        savedSearchStore.set(newStore);

        savedSearchStore.update((store) => {
          return store.concat(search);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

    /**
     * function to get all saved search for active user
     */
    static getAll = async () => {
        let urls = get(savedSearchStore)

        if (urls.length === 0) {
            const email = getEmail()
            // GET FROM API
            try {
                urls = await preferredService.get(email)
                // SET STORE
                savedSearchStore.set(urls)

            } catch (error) {
                console.log(error);
            }

        }
        return urls.sort((s1, s2) => {
          const date1 = (new Date(s1.dateAdded as string)).getTime();
          const date2 = (new Date(s2.dateAdded as string)).getTime();
    
          if(date1 > date2){
            return 1;
          }
          if(date1 < date2){
            return -1;
          }
          return 0;
        });

    }

  /**
   * function to add new search to the active user
   * @param url
   */
  static add = async (url: SavedSearch) => {
    const email = getEmail();

    try {
      const newUrl = await preferredService.add(email, url);
      // Update the Store
      savedSearchStore.update((store) => {
        return store.concat(newUrl);
      });

    } catch (e) {
      throw e;
    }
  };

    /**
     * function to delete search from the active user
     * @param url
     */
    static delete = async (url: SavedSearch) => {
        const email = getEmail()
        try {
            // DELETE ON API
            await preferredService.delete(email, url)

            // SET NEW STORE
            const oldStore = get(savedSearchStore)
            const newStore = oldStore.filter(saved => {
                return saved.id !== url.id
            })
            savedSearchStore.set(newStore)

        } catch (e) { }
        return
    }
}
