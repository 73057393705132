<script lang="ts">
  /**
   *
   * CVResult.svelte
   *
   * Desc : Page. Result CV page
   *
   * Author : Dmytro Teliakovskyi, Nicolas Galois, Sebastien Hoarau
   *
   * Project : cocolake
   *
   */


  // Applications
  import { loadResult, loadMoreCV, clearCVs, changeCurrentCV, changeTimeRange, getIdSearch } from "../../application/ResultController";
  import SavedSearchController from "../../../cvSearch/application/SavedSearchController";
  
  // Stores
  import { cvResultStore } from "../../infrastructure/store/CVResultStore";

  // Constants
  import { timeRangesSearch } from '../../../../constants/timeRangesSearch';

  // Components
  import Header from "../../../../lib/Header.svelte";
  import Spinner from "../../../../lib/Spinner.svelte";
  import Item from "../components/Item.svelte";
  import InfiniteScroll from "../components/InfiniteScroll.svelte";
  import TiDelete from "svelte-icons/ti/TiDelete.svelte";

  // Helpers
  import { isVisited } from "../../application/VisitedCVHelper";

  //Utils
  import { onMount, onDestroy } from "svelte";
  import { navigate } from "svelte-navigator";
  import FaRedoAlt from "svelte-icons/fa/FaRedoAlt.svelte";


  const url = window.location.href.split("/")[4];

  const idSearchPotential: string = getIdSearch(url);
  let isLoading = false;
  let threshold = 0;
  let index = 0;
  let urlFile: string;
  let searchTimeRange = timeRangesSearch[timeRangesSearch.length - 1];
  let cvMaxDate : Date;
  
  /**
   * Initialization.
   */
  onMount(async () => {
    isLoading = true;

    // Get result stores
    await loadResult(threshold, url, searchTimeRange);

    // If there is result, display list of cvs
    if ($cvResultStore.cvs) {
      if (idSearchPotential !== "notId") {
        if ($cvResultStore.cvs && $cvResultStore.cvs.length > 0) {
          SavedSearchController.update(
            idSearchPotential,
            $cvResultStore.cvs[0].id
          );
        }
      }

      // Display the currentCV
      if ($cvResultStore.currentCV) {
          await displayCurrentCV();
      }

      // Set the latest date from CV results
      cvMaxDate = new Date(Math.max.apply(null, $cvResultStore.cvs.map(cv => new Date(cv.dateAdded).getTime())));
    }
    
    isLoading = false;
  });

  /**
   * Retrieves the file corresponding to the current CV and displays it in the PDF reader
   */
  async function displayCurrentCV() {
    let urlB = `data:application/pdf;base64,${$cvResultStore.currentCV?.data}`;
    let res = await fetch(urlB);
    let blob = await res.blob();
    urlFile = window.URL.createObjectURL(blob);
  }

  onDestroy(() => {
    // Clear result store
    clearCVs();
  });

  function redo() {
    clearCVs();
    navigate("/");
  }

  /**
   * Opens the date picker for the date input field
   */
  function openDatePicker() {
    (document.querySelector("input[type=date]") as HTMLInputElement).showPicker();
  }

  /**
   * Resets the time range filter
   */
  async function resetTimeRange() {
    searchTimeRange = timeRangesSearch[timeRangesSearch.length - 1];
    await setTimeRange();
  }

  /**
   * Creates and applies a time range filter from the value of the date input field
   */
  async function inputTimeRange() {
    let newDate = new Date((document.querySelector("input[type=date]") as HTMLInputElement).value);
    searchTimeRange.endDate = () => newDate;
    await setTimeRange();
  }

  /**
   * Retrieves the time range filter chosen in the select menu, ensures it is valid and applies it
   */
  async function handleTimeRangeChange() {
    let newTimeRangeValue = (document.getElementById("timerange") as HTMLSelectElement).value;
    let filteredTimeRanges = timeRangesSearch.filter(timeRange => timeRange.value === newTimeRangeValue);
    if (filteredTimeRanges.length) {
      let previousEndDate = searchTimeRange.endDate;
      searchTimeRange = filteredTimeRanges[0];
      // does not apply the filter if the user selected the custom date option
      if (searchTimeRange.value === "custom") {
        searchTimeRange.endDate = previousEndDate;
      } else {
        await setTimeRange();
      }
    }
  }

  /**
   * Applies the current time range filter
   */
  async function setTimeRange() {
    changeTimeRange(searchTimeRange);
    if ($cvResultStore.currentCV === undefined && $cvResultStore.filteredCvs.length) {
      try {
        await changeCurrentCV($cvResultStore.filteredCvs[0]);
        await displayCurrentCV();
      } catch (error) {
        console.log(error);
      }
    }
  }

  /**
   * listener of keypress in result page
   * @param event
   */
  async function handleKeydown(event) {
      const keyCode = event.keyCode;
      try {
        if (keyCode == 38 && index > 0) {
          event.preventDefault();
          let ul = document.getElementById('list') as HTMLElement;
          let li = document.getElementsByClassName('picked').item(0) as Element;

          ul.scrollTop -= li.clientHeight;
          await changeCurrentCV($cvResultStore.filteredCvs[--index]);
          await displayCurrentCV();

        }

        if (keyCode == 40 && index < $cvResultStore.filteredCvs.length - 1) {
          event.preventDefault();
          let ul = document.getElementById('list') as HTMLElement;
          let li = document.getElementsByClassName('picked').item(0) as Element;

          ul.scrollTop += li.clientHeight;
          await changeCurrentCV($cvResultStore.filteredCvs[++index]);
          await displayCurrentCV();
          
        }
        
      } catch (error) {
        console.log(error);
      }
  }
</script>

<svelte:window on:keydown={handleKeydown}/>

<Header back />

{#if isLoading}
  <Spinner />
{:else}
  <section>
    {#if $cvResultStore.cvs}
      <aside>
        <div id="header">
          <p>{$cvResultStore.nbResult} result{$cvResultStore.nbResult > 1 ? "s" : ""} for your search</p>
          {#if searchTimeRange.value === "custom"}
            <div id="calendar">
              <label for="">Since </label>
              <input type="date" max={cvMaxDate.toISOString().split("T")[0]}
                on:click|preventDefault={ openDatePicker }
                on:input|preventDefault={ inputTimeRange }>
              <button on:click|preventDefault={ resetTimeRange }><TiDelete /></button>
            </div>
          {:else}
          <select id="timerange" on:input|preventDefault={handleTimeRangeChange}>
            {#each timeRangesSearch as timeRange}
            <option
              disabled={timeRange.endDate() > cvMaxDate}
              selected={timeRange.value === searchTimeRange.value} value={timeRange.value}>
              {timeRange.label}
            </option>
            {/each}
          </select>
          {/if}
        </div>
        <ul id="list">
          {#each $cvResultStore.filteredCvs as cv (cv.id)}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <li
              class:visited={isVisited(cv.id)}
              class:picked={$cvResultStore.currentCV === cv}
              on:click|preventDefault={ () => {
                if($cvResultStore.currentCV !== cv) {
                  changeCurrentCV(cv).then(() => {
                    displayCurrentCV();
                  });
                }
                
                index = $cvResultStore.filteredCvs.indexOf(cv);
              }}
            >
              <Item {cv} />
            </li>
          {/each}
          <InfiniteScroll
            hasMore={threshold <= $cvResultStore.nbResult - 1}
            on:loadMore={async () => {
              threshold += 10;
              await loadMoreCV(threshold, url, searchTimeRange);
            }}
          />
        </ul>
      </aside>
      {#if $cvResultStore.currentCV === undefined}
        <div class="no-cv-aside">
          <h3>No result for the selected time range</h3>
        </div>
      {:else}
        <object
          title="currentCV"
          data="{urlFile}"
          type="application/pdf"
        />
      {/if}
    {:else}
      <!-- No cv -->
      <div>
        <h3>There no result for your request. Please try again</h3>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <i on:click={redo}>
          <FaRedoAlt />
        </i>
      </div>
    {/if}
  </section>
{/if}

<style lang="scss">
  select {
    background-color: rgb(82, 86, 89);
    border-radius: 3px;
    padding: 0.5rem 1rem;

    &:hover {
      color: #37f489;
      animation: jelly 0.5s 1;
    }
  }

  section {
    display: flex;
    justify-content: center;
    overflow: auto;
  }

  #header {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.55rem;
    padding-right: 1.55rem;
    font-size: 1.6rem;
    font-weight: 500;
    background: rgb(50, 54, 57);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-shadow: 0 -2px 8px rgb(0 0 0 / 9%), 0 4px 8px rgb(0 0 0 / 6%),
      0 1px 2px rgb(0 0 0 / 30%), 0 2px 6px rgb(0 0 0 / 15%);
    z-index: 1;
  }

  #calendar {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
  }

  input[type="date"] {
    background-color: rgb(82, 86, 89);
    border-radius: 3px;
    padding: 0.5rem 1rem;
    width: 11rem;

    &:hover {
      color: #37f489;
      animation: jelly 0.5s 1;
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }

  button {
    width: 3rem;
    height: 3rem;

    &:hover {
      color: #37f489;
      animation: jelly 0.5s 1;
    }
  }

  .no-cv-aside {
    flex: 1;
    height: calc(100vh - 6.6rem);
    margin: 0;
  }

  object {
    flex: 1;
    height: calc(100vh - 6.6rem);
  }

  aside {
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    background-color: rgb(82, 86, 89);
    overflow: hidden;
    color: white;
    border-right: 1px solid white;
  }

  p {
    padding: 1.55rem 2rem;
    font-size: 1.6rem;
    font-weight: 500;
  }

  ul {
    height: calc(100vh - 12rem);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }

  li {
    cursor: context-menu;
    font-size: 1.8rem;
    padding: 1.4rem 0;
    overflow: visible;
  }

  .picked {
    background: #323639;
    color: #37f489;
  }

  .visited {
    background: #323639;
  }

  div {
    margin-top: 16rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    color: white;
    z-index: 100;
  }

  h3 {
    font-size: 3.2rem;
    font-weight: 400;
  }

  i {
    cursor: pointer;
    display: block;
    height: 12rem;
    width: 12rem;
    transition: all 0.2s;

    &:hover {
      color: #36f777;
      animation: jelly 0.5s 1;
    }
  }

  @keyframes jelly {
    from,
    to {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    25% {
      -webkit-transform: scale(0.95, 1.01);
      transform: scale(0.95, 1.01);
    }
    50% {
      -webkit-transform: scale(1.01, 0.95);
      transform: scale(1.01, 0.95);
    }
    75% {
      -webkit-transform: scale(0.99, 1.09);
      transform: scale(0.99, 1.01);
    }
  }
</style>
