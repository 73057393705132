/**
 *
 * CVResultStore.ts
 *
 * Desc : Infrastructure. Store for result cvs
 *
 * Author : Nicolas Galois
 *
 * Project : cocolake
 *
 */


// Models
import type { CVResult } from '../../../../models/CVResult';

// Utils
import { writable } from 'svelte/store';


function createCVResultStore() {
  const { subscribe, set, update } = writable({} as CVResult);

  return {
    subscribe,
    set,
    update,
    reset: () => set({} as CVResult),
  };
}

export const cvResultStore = createCVResultStore();
