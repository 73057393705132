/**
 * 
 * ApiRepository.ts
 *
 * Desc : Refactored class to request the back with axios
 *
 * Authors : Nicolas Galois, Dmytro Teliakovskyi
 * 
 * Project : cocolake
 * 
 */


// Controllers
import { getIdToken } from "../modules/authentication/application/UserController";

// Utils
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';


// Api Repository for making requests to the server
export default class ApiRepository {

  /**
   * Function for initialising GET request options
   * @private
   * @param params
   */
  private static async initGetOptions(params: any): Promise<AxiosRequestConfig> {
    return {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${await getIdToken()}`,
      },
      params: params
    };
  }

  /**
   * GET request to the server
   * @param url
   * @param params
   */
  static async get(url: string, params?: any): Promise<AxiosResponse<any>> {
    const config = await this.initGetOptions(params);
    return axios.get(`${global.APP_API_URL + url}`, config);
  }

  /**
   * Function for initialising POST request options
   * @param url
   * @param body
   */
  private static async initPostOptions(url: string, body: any): Promise<AxiosRequestConfig> {
    return {
      method: 'POST',
      url: global.APP_API_URL + url,
      headers: {
        Authorization: `Bearer ${await getIdToken()}`,
      },
      data: body,
    };
  }

  /**
   * POST request to the server
   * @param url
   * @param body
   */
  static async post(url: string, body: any) {
    const options = await this.initPostOptions(url, body);
    return axios(options);
  }

  /**
   * Function for initialising DELETE request options
   * @param url 
   * @returns 
   */
  private static async initDeleteOptions(url: string): Promise<AxiosRequestConfig> {
    return {
      method: "DELETE",
      url: global.APP_API_URL + url,
      headers: {
        Authorization: `Bearer ${await getIdToken()}`,
      }
    }
  }

  /**
   * DELETE request to the server
   * @param url 
   * @returns 
   */
  static async delete(url: string) {
    const options = await this.initDeleteOptions(url);
    return axios(options);
  }
}