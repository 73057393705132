<script lang="ts">
  /**
   *
   * App.svelte
   *
   * Desc : Page. Application svelte
   *
   * Author : Sebastien Hoarau, Dmytro Teliakovskyi, Nicolas Galois
   *
   * Project : Cocolake
   *
   */

  
  // Components
  import Authentication from "./modules/authentication/presentation/page/Authentication.svelte";
  import Errors from "./Errors.svelte";
  import CVSearch from "./modules/cvSearch/presentation/page/CVSearch.svelte";
  import CVResult from "./modules/cvResult/presentation/page/CVResult.svelte";
  import CVDisplay from "./modules/cvResult/presentation/page/CVDisplay.svelte";

  // Utils
  import { Router, Route, navigate } from "svelte-navigator";

  
</script>

<Router primary={false}>
  <main>
    <Route path="/">
      {#if sessionStorage.length >= 4}
        {navigate("/search")}
      {:else}
        {navigate("/auth")}
      {/if}
    </Route>
    <Route path="/auth">
      <Authentication />
    </Route>
    <Route path="/search">
      <CVSearch />
    </Route>
    <Route path="/result/:query">
      <CVResult />
    </Route>
    <Route path="/display/:id">
      <CVDisplay />
    </Route>

    <!-- Page Not found-->
    <Route let:params>
      <Errors />
      <!--<Errors status={params.status} message={params.message} /> -->
    </Route>
  </main>
</Router>

<style lang="scss">
  main {
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    background: #525659;
  }
</style>
