/**
 *
 * CVSearchController.ts
 *
 * Desc : CVSearchController. Controller to manage the search of CV
 *
 * Author : Sebastien Hoarau, Dmytro Teliakovskyi
 *
 * Project : cocolake
 *
 */


// Utils
import { navigate } from 'svelte-navigator';

/**
 * function to handle search
 * @param event
 */
export const handleSearch = async (event) => {
  const { keywords, item, distance, type } = event.detail.data;

  if (!item) {
    navigate(`/result/search=${keywords}&type=${type}`);
  } else if(item && item.country && item.city) {
    navigate(`/result/search=${keywords}&city=${item.city}&country=${item.country}&distance=${distance}&type=${type}`);
  } else {
    navigate(`/result/search=${keywords}&country=${item.country}&type=${type}`);
  }
};
