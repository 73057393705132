/**
 *
 * SavedSearchStore.ts
 *
 * Desc : Store. Store used by the SavedSearch view to display model
 *
 * Author : Nicolas Galois
 *
 * Project : Cocolake
 *
 */


// Models
import type {SavedSearch} from "../../domain/types/SavedSearch";

// Utils
import {writable} from "svelte/store"


export const savedSearchStore = writable([] as SavedSearch[])

/*
[
    {keywords: 'Java%20Php'},
    {keywords: 'Php'},
    {keywords: 'Java%20Php', location: {city: 'Paris', country: 'France'}},
    {keywords: 'Android', location: {city: 'Paris', country: 'France'}},
    {keywords: 'React', location: {city: 'Grenoble', country: 'France'}},
    {keywords: 'CSS Genius', location: {city: 'Kiev', country: 'Ukraine'}},
    {keywords: 'Angular%20MongoDB', location: {city: 'Paris', country: 'France'}},
    {keywords: 'Java%20Spring', location: {city: 'Paris', country: 'France'}},
]
 */