<script lang="ts">
  /**
   *
   * Welcome.svelte
   *
   * Desc : Component. To display message welcome
   *
   * Author : Dmytro Teliakovskyi
   *
   * Project : Cocolake
   *
   */


  // Applications
  import { getUserName } from '../../../authentication/application/UserController';

  
</script>

{#await getUserName() then userName}
  <section>
    <h2>
      Welcome {userName}
    </h2>
    <p>Find the perfect CV for your need!</p>
  </section>
{/await}

<style lang="scss">
  section {
    margin: 4rem;
    height: 40vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    color: white;
    background: rgb(50, 54, 57);
    border-radius: 1rem;
  }
  h2 {
    text-align: center;
    font-size: 4rem;
    font-weight: 500;
  }
  p {
    font-size: 2.6rem;
    text-align: center;
  }
</style>
