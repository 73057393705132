/**
 *
 * VisitedCVHelper.ts
 *
 * Desc : Helper. Helper to store on session when we visited CV
 *
 * Author : Dmytro Teliakovskyi, Sebastien Hoarau
 *
 * Project : cocolake
 *
 */


/**
 * func to set a cv as visited in session
 * @param cvId
 */
export function setVisitedCV(cvId: string) {
    const visitedCVs = sessionStorage.getItem('visited') as string;

    if (visitedCVs) {
        const parsedVisited = JSON.parse(visitedCVs);

        if (!isVisited(cvId)) {
            parsedVisited.push(cvId);
        }

        sessionStorage.setItem('visited', JSON.stringify(parsedVisited));
    } else {
        sessionStorage.setItem('visited', JSON.stringify([cvId]));
    }
}

/**
 * func to check if visited by client during session
 * @param cvId
 */
export function isVisited(cvId: string) {
    return (sessionStorage.getItem('visited') as string).includes(cvId);
}