<script lang="ts">
  /**
   *
   * Item.svelte
   *
   * Desc : Components. Item to display one CV
   *
   * Author : Dmytro Teliakovskyi, Nicolas Galois
   *
   * Project : cocolake
   *
   */


  // Application
  import { getSkills, displayFileName } from '../../application/ResultHelper';
  import { getCandidate, getFields } from '../../application/CandidateController';
  
  // Models
  import type { CareerFields } from '../../domain/types/CareerFields';
  import type { Candidate } from '../../domain/types/Candidate';
  import type { CV } from '../../../../models/CV';

  // Components
  import FaMapMarkerAlt from 'svelte-icons/fa/FaMapMarkerAlt.svelte';
  import FaCity from 'svelte-icons/fa/FaCity.svelte';
  import FaCalendarDay from 'svelte-icons/fa/FaCalendarDay.svelte';
  import FaPlus from 'svelte-icons/fa/FaPlus.svelte';
  import CandidateForm from './CandidateForm.svelte';
  import FaEye from 'svelte-icons/fa/FaEye.svelte';

  // Utils
  import { onMount } from 'svelte';
  

  // Imported variables
  export let cv: CV;

  // Local Variables
  const skills = getSkills(cv.skills.languages, cv.skills.frameworks, cv.skills.database, cv.skills.tool);
  let isOpen = false;

  let careerFields: CareerFields;
  let candidate : Candidate;

  function closeForm() {
    isOpen = false;
  }

  onMount(async () => {
    careerFields = await getFields();
  });

  const handleNavigate = () => {
    window.open(
      `${global.CRM_URL}main.aspx?` +
      `appid=${global.CRM_APP_ID}` +
      '&newWindow=true&pagetype=entityrecord' +
      `&etn=new_career&id=${cv.careerId}`,
      '_blank'
    );
  };

  const handleForm = async () => {
    try {
      candidate = await getCandidate(cv.email, cv.id);

      if (candidate.contactId && candidate.career) {
        cv.isFoundOnCrm = true;
        cv.careerId = candidate.career.id;
        isOpen = false;
      } else {
        isOpen = true;
      }
    } catch (e) {
      isOpen = true;
    }
  };
</script>

<article>
  <header>
    <h6>
      {displayFileName(cv.fileName)}
    </h6>
    <div class="date">
      <i>
        <FaCalendarDay />
      </i>
      <span>{new Date(cv.dateAdded.split('T')[0]).toLocaleString().split(' ')[0]}</span>
    </div>
  </header>
  <main>
    <section>
      <div>
        {#each skills as skill}
          <p>{skill}</p>
        {/each}
      </div>
      {#if cv.isFoundOnCrm}
        <div class="add" on:click={() => handleNavigate()}>
          <i>
            <FaEye />
          </i>
          <span>See on CRM</span>
        </div>
      {:else}
        <div class="add" on:click={() => handleForm()}>
          <i>
            <FaPlus />
          </i>
          <span>Add to CRM</span>
        </div>
      {/if}
    </section>
    {#if cv.country || cv.city}
      <section class="location">
        {#if cv.country}
        <div>
          <i>
            <FaMapMarkerAlt />
          </i>
          <span>{cv.country}</span>
        </div>
        {/if}
        {#if cv.city}
        <div>
          <i>
            <FaCity />
          </i>
          <span>{cv.city}</span>
        </div>
        {/if}
      </section>
    {/if}
    {#if isOpen}
      <CandidateForm on:close={closeForm} {cv} {careerFields} {candidate} />
    {/if}
  </main>
</article>

<style lang="scss">
  article {
    padding: 0 2rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;

    &:hover {
      color: #37f489;
    }
  }
  main {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  section {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  header {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: 400;
  }
  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  h6 {
    flex: 1;
    font-size: 1.5rem;
    line-height: 1;
  }
  p {
    font-size: 1.1rem;
    padding: 0.2rem 1rem;
    border: 1px solid;
    border-radius: 2rem;
  }
  .date {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 1.2rem;
  }
  .add {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid;
    border-radius: 0.5rem;
    padding: 0.6rem;

    &:hover {
      animation: jelly 0.5s;
    }
  }
  @keyframes jelly {
    from,
    to {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    25% {
      -webkit-transform: scale(0.95, 1.01);
      transform: scale(0.95, 1.01);
    }
    50% {
      -webkit-transform: scale(1.01, 0.95);
      transform: scale(1.01, 0.95);
    }
    75% {
      -webkit-transform: scale(0.99, 1.09);
      transform: scale(0.99, 1.01);
    }
  }
  .location {
    justify-content: start;
  }
  i {
    display: flex;
    height: 1rem;
    width: 1rem;
  }
  span {
    line-height: 1;
    font-size: 1.2rem;
  }
</style>
