/**
 *
 * countries.ts
 *
 * Desc : Constant. Countries code phone number
 *
 * Author : Sebastien Hoarau
 *
 * Project : cocolake
 *
 */


export const countries = [
    {
        flag: '🇩🇿 +213',
        value: '+213',
        name: 'ALGERIA',
        code: 'DZ',
    },
    {
        flag: '🇦🇹 +43',
        value: '+43',
        name: 'AUSTRIA',
        code: 'AT',
    },
    {
        flag: '🇧🇪 +32',
        value: '+32',
        name: 'BELGIUM',
        code: 'BE',
    },
    {
        flag: '🇧🇬 +359',
        value: '+359',
        name: 'BULGARIA',
        code: 'BG',
    },
    {
        flag: '🇭🇷 +385',
        value: '+385',
        name: 'CROATIA',
        code: 'HR',
    },
    {
        flag: '🇨🇾 +357',
        value: '+357',
        name: 'CYPRUS',
        code: 'CY',
    },
    {
        flag: '🇨🇿 +420',
        value: '+420',
        name: 'CZECHIA',
        code: 'CZ',
    },
    {
        flag: '🇩🇰 +45',
        value: '+45',
        name: 'DENMARK',
        code: 'DK',
    },
    {
        flag: '🇪🇪 +372',
        value: '+372',
        name: 'ESTONIA',
        code: 'EE',
    },
    {
        flag: '🇫🇮 +358',
        value: '+358',
        name: 'FINLAND',
        code: 'FI',
    },
    {
        flag: '🇫🇷 +33',
        value: '+33',
        name: 'FRANCE',
        code: 'FR',
    },
    {
        flag: '🇫🇷 +262',
        value: '+262',
        name: 'FRANCE',
        code: 'RE',
    },
    {
        flag: '🇫🇷 +508',
        value: '+508',
        name: 'FRANCE',
        code: 'PM',
    },
    {
        flag: '🇫🇷 +590',
        value: '+590',
        name: 'FRANCE',
        code: 'GP',
    },
    {
        flag: '🇫🇷 +594',
        value: '+594',
        name: 'FRANCE',
        code: 'GF',
    },
    {
        flag: '🇫🇷 +596',
        value: '+596',
        name: 'FRANCE',
        code: 'MQ',
    },
    {
        flag: '🇫🇷 +687',
        value: '+687',
        name: 'FRANCE',
        code: 'NC',
    },
    {
        flag: '🇫🇷 +689',
        value: '+689',
        name: 'FRANCE',
        code: 'PF',
    },
    {
        flag: '🇩🇪 +49',
        value: '+49',
        name: 'GERMANY',
        code: 'DE',
    },
    {
        flag: '🇬🇷 +30',
        value: '+30',
        name: 'GREECE',
        code: 'GR',
    },
    {
        flag: '🇭🇺 +36',
        value: '+36',
        name: 'HUNGARY',
        code: 'HU',
    },
    {
        flag: '🇮🇪 +353',
        value: '+353',
        name: 'IRELAND',
        code: 'IE',
    },
    {
        flag: '🇮🇹 +39',
        value: '+39',
        name: 'ITALY',
        code: 'IT',
    },
    {
        flag: '🇱🇻 +371',
        value: '+371',
        name: 'LATVIA',
        code: 'LV',
    },
    {
        flag: '🇱🇹 +370',
        value: '+370',
        name: 'LITHUANIA',
        code: 'LT',
    },
    {
        flag: '🇱🇺 +352',
        value: '+352',
        name: 'LUXEMBOURG',
        code: 'LU',
    },
    {
        flag: '🇲🇹 +356',
        value: '+356',
        name: 'MALTA',
        code: 'MT',
    },
    {
        flag: '🇲🇦 +212',
        value: '+212',
        name: 'MOROCCO',
        code: 'MA',
    },
    {
        flag: '🇳🇱 +31',
        value: '+31',
        name: 'NETHERLANDS',
        code: 'NL',
    },
    {
        flag: '🇵🇱 +48',
        value: '+48',
        name: 'POLAND',
        code: 'PL',
    },
    {
        flag: '🇵🇹 +351',
        value: '+351',
        name: 'PORTUGAL',
        code: 'PT',
    },
    {
        flag: '🇷🇴 +40',
        value: '+40',
        name: 'ROMANIA',
        code: 'RO',
    },
    {
        flag: '🇪🇸 +34',
        value: '+34',
        name: 'SPAIN',
        code: 'ES',
    },
    {
        flag: '🇸🇰 +421',
        value: '+421',
        name: 'SLOVAKIA',
        code: 'SK',
    },
    {
        flag: '🇸🇮 +386',
        value: '+386',
        name: 'SLOVENIA',
        code: 'SI',
    },
    {
        flag: '🇸🇪 +46',
        value: '+46',
        name: 'SWEDEN',
        code: 'SE',
    },
    {
        flag: '🇹🇳 +216',
        value: '+216',
        name: 'TUNISIA',
        code: 'TN',
    },
    {
        flag: '🇺🇦 +380',
        value: '+380',
        name: 'UKRAINE',
        code: 'UA',
    },
    {
        flag: '🇺🇸 +1',
        value: '+1',
        name: 'UNITED STATES',
        code: 'US',
    },
];