/**
 *
 * AutoCompleteService.ts
 *
 * Desc : Service. for autocomplete city and country
 *
 * Author : Dmytro Teliakovskyi
 *
 * Project : cocolake
 *
 */


// Models
import type { AutocompleteInterface } from '../interfaces/AutocompleteInterface';
import type { AutoCompleteResult } from '../types/AutoCompleteResult';


export class AutocompleteService {
  constructor(protected autocompleteRepository: AutocompleteInterface) {}

  /**
   * function to find suggestions by keywords
   * @param keyword
   */
  async find(keyword: string): Promise<AutoCompleteResult[]> {
    return this.autocompleteRepository.find(keyword);
  }
}
