/**
  *
  * CandidateController.ts
  *
  * Desc: Application. controller for candidate (contact and career on CRM)
  *
  * Author : Dmytro Teliakovskyi, Nicolas Galois
  *
  * Project : cocolake
  *
  */


// Applications
import { getEmail } from '../../authentication/application/UserController';

// Models
import { CandidateService } from '../domain/services/CandidateService';
import type { CareerFields } from '../domain/types/CareerFields';
import type { Candidate } from '../domain/types/Candidate';


const candidateFormService = new CandidateService();

/**
 * get candidate by email
 * @param email
 * @param cvId, use to update cv on elasticSearch
 */
export async function getCandidate(email: string, cvId: string): Promise<Candidate> {
  try {
    return await candidateFormService.get(email, cvId);
  } catch (e) {
    throw e;
  }
}

/**
 * get fields to select options: career name and contract type
 */
export async function getFields(): Promise<CareerFields> {
  let candidateFields = sessionStorage.getItem('candidateFields');

  if (!candidateFields) {
    const fields = await candidateFormService.getFields();
    sessionStorage.setItem('candidateFields', JSON.stringify(fields));
    candidateFields = JSON.stringify(fields);
  }

  return JSON.parse(candidateFields);
}

/**
 * create candidate on crm, (a contact and a career)
 * @param candidate
 * @param cvId, use to update cv on elasticSearch
 */
export async function createCandidate(candidate: Candidate, cvId: string, annotation: string): Promise<Candidate> {
  const email : string = getEmail();
  return await candidateFormService.create(candidate, cvId, email, annotation);
}
