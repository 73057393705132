<script lang="ts">
  /**
   *
   * CVSearch.svelte
   *
   * Desc : Page. To display Search page
   *
   * Author : Dmytro Teliakovskyi
   *
   * Project : Cocolake
   *
   */

  // Applications
  import { handleSearch } from '../../application/CVSearchController';

  // Components
  import Header from '../../../../lib/Header.svelte';
  import Welcome from '../components/Welcome.svelte';
  import Form from '../components/Form.svelte';
  import SavedSearch from '../components/SavedSearch.svelte';

</script>

<Header />

<main>
  <Welcome />
  <Form on:search={handleSearch} />
  <SavedSearch />
</main>

<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
  }
</style>
