<script lang="ts">
  /**
   *
   * Spinner.svelte
   *
   * Desc : Lib. Spinner svelte
   *
   * Author : Dmytro Teliakovskyi
   *
   * Project : cocolake
   *
   */


</script>
<section class="spinner">
  <div class="shadow"></div>
  <div class="box"></div>
</section>

<style lang="scss">
  .spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
    opacity: 1;
  }

  .box {
    width: 5rem;
    height: 5rem;
    background: #37f489;
    border-radius: 3px;
    animation: animate 0.5s linear infinite;
  }

  @keyframes animate {
    17% {
      border-bottom-right-radius: 3px;
    }
    25% {
      transform: translateY(9px) rotate(22.5deg);
    }
    50% {
      transform: translateY(18px) scale(1, 0.9) rotate(45deg);
      border-bottom-right-radius: 4rem;
    }
    75% {
      transform: translateY(9px) rotate(67.5deg);
    }
    100% {
      transform: translateY(0) rotate(90deg);
    }
  }

  .shadow {
    width: 5rem;
    height: 5px;
    background: rgb(17, 17, 17);
    opacity: 0.1;
    position: absolute;
    top: 6rem;
    left: 0;
    border-radius: 50%;
    animation: shadow 0.5s linear infinite;
  }

  @keyframes shadow {
    50% {
      transform: scale(1.2, 1);
    }
  }
</style>
