<script lang="ts">
  /**
   *
   * Form.svelte
   *
   * Desc : Components. Form to search CV
   *
   * Author : Dmytro Teliakovskyi, Sebastien Hoarau
   *
   * Project : Cocolake
   *
   */


  // Applications
  import { find } from '../../application/AutocompleteController';
  import SavedSearchController from '../../application/SavedSearchController';
  
  // Types
  import type { AutoCompleteDisplayResult } from '../../domain/types/AutoCompleteDisplayResult';
  import type { SavedSearch } from '../../domain/types/SavedSearch';

  // Constants
  import { typesSearch } from '../../../../constants/typesSearch';
  
  // Components
  import AutoComplete from 'simple-svelte-autocomplete';
  import FaSearch from 'svelte-icons/fa/FaSearch.svelte';
  import FaMapMarkerAlt from 'svelte-icons/fa/FaMapMarkerAlt.svelte';
  import FaHeart from 'svelte-icons/fa/FaHeart.svelte';

  // Utils
  import { createEventDispatcher } from 'svelte';
  // import { fade } from 'svelte/transition';

  const dispatch = createEventDispatcher();

  let keywords: string | undefined;
  let item: AutoCompleteDisplayResult;
  let distance: number = 5;
  let cancel: boolean = false;
  let type: string = "keywords";

  export function handleSubmit() {
    if (keywords || type === "keywords") {
      if (keywords === "") {
        keywords = undefined;
      }
      dispatch('search', { data: { keywords, item, distance, type } });
    } else {
      cancel = true;
    }
  }

  $: if (keywords && type === "keywords") {
    cancel = false;
  }

  const handleAddUrl = () => {
    let url: SavedSearch = {};
    if (keywords === "") { keywords = undefined; }

    if (keywords) {
      url.keywords = keywords as string;
    }

    if (item) {
      if (item.city) {
        url.location = {
          city: item.city,
          country: item.country,
          distance: distance,
        }
      } else {
        url.location = { country: item.country };
      }  
    }

    SavedSearchController.add(url);
  };
</script>

<section>
  <form>
    <div class="keyword" class:cancel>
      <i>
        <FaSearch />
      </i>
      <input bind:value={keywords} type="text" placeholder="Your Search" />
      <select bind:value={type}>
        {#each typesSearch as type}
          <option value={type.value}>{type.label}</option>
        {/each}
      </select>
    </div>
    {#if type === "keywords"}
    <!--<div in:fly={{x:100}} out:fly={{x:100}} > if we need to add transition with fly svelte-->
      <div class="city">
        <i>
          <FaMapMarkerAlt />
        </i>
        <AutoComplete
          searchFunction={find}
          bind:selectedItem={item}
          labelFieldName="label"
          maxItemsToShowInList="5"
          hideArrow="true"
          delay="200"
          showClear="true"
          localFiltering="false"
          placeholder="Set a location"
        />
      </div>
      {#if item && item.city}
        <div>  <!--transition:fade={{ duration: 150 }}> -->
          <input id="distance" type="range" bind:value={distance} min="5" max="100" disabled={!item} />
          <label for="distance">{distance} <span> km</span></label>
        </div>
      {/if}
      <div on:click={() => handleAddUrl()}>
        <i class="pointer">
          <FaHeart />
        </i>
      </div>
    
    {/if}

    <div class:cancel>
      <button on:click|preventDefault={handleSubmit}> Find a CV </button>
    </div>
  </form>
</section>

<style lang="scss">
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -9rem;
  }

  form {
    max-width: 90vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background: white;
    border-radius: 1rem;
    box-shadow: 4px 0 4rem rgb(rgb(50, 54, 57), 80%);
    transition: all 0.2s;

    @media only screen and (max-width: 37.5em) {
      max-width: 60vw;
    }
  }
  div {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 2rem;

    &:hover,
    &:active {
      i {
        color: #37f489;
      }
    }
  }

  .keyword {
    gap: 1rem;

    & > input {
      min-width: 22rem;
    }
  }

  button {
    cursor: pointer;
    white-space: nowrap;
    color: white;
    text-align: center;
    padding: 2rem 4rem;
    background: rgb(82, 86, 89);
    border-radius: 1rem;
    transition: all 0.2s;

    &:hover {
      background: #37f489;
    }
  }
  .cancel {
    transition: none;

    button:hover,
    button:active {
      background: #ea140f;
    }
    i {
      color: #ea140f;
      animation: jelly 0.5s 1;
    }
  }
  label {
    display: flex;
    min-width: 5ch;
  }

  input {
    width: 100%;
    height: 2.25em;
    &::placeholder {
      color: rgba(#4d4d4d, 0.4);
    }
    &[type='range'] {
      background-color: transparent;
      -webkit-appearance: none;
      &:focus {
        outline: none;
      }
      &::-webkit-slider-runnable-track {
        background: #595959;
        border: 0 solid rgba(89, 89, 89, 0);
        border-radius: 25px;
        width: 100%;
        height: 5px;
        cursor: pointer;
      }
      &::-webkit-slider-thumb {
        margin-top: -5px;
        width: 15px;
        height: 15px;
        background: #37f489;
        border-radius: 5px;
        cursor: pointer;
        -webkit-appearance: none;
      }
      &:focus::-webkit-slider-runnable-track {
        background: #b0b0b0;
      }
      &::-moz-range-track {
        background: #595959;
        border: 0 solid rgba(89, 89, 89, 0);
        border-radius: 25px;
        width: 100%;
        height: 5px;
        cursor: pointer;
      }
      &::-moz-range-thumb {
        width: 15px;
        height: 15px;
        background: #37f489;
        border-radius: 5px;
        cursor: pointer;
      }
      &::-ms-track {
        background: transparent;
        border-color: transparent;
        border-width: 5px 0;
        color: transparent;
        width: 100%;
        height: 5px;
        cursor: pointer;
      }
      &::-ms-fill-lower {
        background: #020202;
        border: 0 solid rgba(89, 89, 89, 0);
        border-radius: 50px;
      }
      &::-ms-fill-upper {
        background: #595959;
        border: 0 solid rgba(89, 89, 89, 0);
        border-radius: 50px;
      }
      &::-ms-thumb {
        width: 15px;
        height: 15px;
        background: #37f489;
        border: 1.8px solid rgba(89, 89, 89, 0.2);
        border-radius: 2px;
        cursor: pointer;
        margin-top: 0;
        /*Needed to keep the Edge thumb centred*/
      }
      &:focus::-ms-fill-lower {
        background: #595959;
      }
      &:focus::-ms-fill-upper {
        background: #b0b0b0;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }

  i {
    color: rgb(82, 86, 89);
    height: 2rem;
    width: 2rem;
    transition: all 0.2s;
  }

  :global(.autocomplete) {
    padding-left: 1rem;
    min-width: 80% !important;
    max-width: 80% !important;
  }

  :global(.autocomplete-input) {
    min-width: 23rem;
    margin: 0 !important;
    padding: 0 !important;
    font-weight: 400 !important;
    font-family: inherit !important;
    color: inherit !important;
  }

  :global(.autocomplete-input::placeholder) {
    font-weight: 400 !important;
    color: rgba(#4d4d4d, 0.4) !important;
  }

  :global(.autocomplete-list) {
    font-weight: 400 !important;
    font-family: inherit !important;
    color: inherit !important;
    border: none !important;
    border-radius: 0.4rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) !important;
  }
  :global(.autocomplete-list-item) {
    font-family: inherit !important;
    padding: 1rem 0;
  }
  :global(.selected) {
    font-family: inherit !important;
    background-color: rgb(228, 227, 227) !important;
    color: inherit !important;
  }
  :global(.autocomplete-clear-button) {
    right: 0 !important;
    padding: 0 !important;
    color: rgb(82, 86, 89);
    top: 60% !important;
    transform: translateY(-60%) !important;
  }

  @keyframes jelly {
    from,
    to {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    25% {
      -webkit-transform: scale(0.95, 1.01);
      transform: scale(0.95, 1.01);
    }
    50% {
      -webkit-transform: scale(1.01, 0.95);
      transform: scale(1.01, 0.95);
    }
    75% {
      -webkit-transform: scale(0.99, 1.09);
      transform: scale(0.99, 1.01);
    }
  }
</style>
