<script lang="ts">
  /**
   *
   * Button.svelte
   *
   * Desc : Lib. Button svelte
   *
   * Author : Dmytro Teliakovskyi
   *
   * Project : cocolake
   *
   */

   
  export let type: string = "primary";
</script>

<button class={type} on:click|preventDefault>
  <slot />
</button>

<style lang="scss">
  .primary {
    background: #37f489;

    &--outline {
      color: #37f489;
      border: 1px solid #37f489;
    }
  }
  .secondary {
    background: #3fb7f1;

    &--outline {
      color: #3fb7f1;
      border: 1px solid #3fb7f1;
    }
  }
  .cancel {
    background: #ff6239;

    &--outline {
      color: #ff6239;
      border: 1px solid #ff6239;
    }
  }
  button {
    cursor: pointer;
    padding: 0.6rem 1.2rem;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    color: #fdfefe;
    background: #fdfefe;
    font-size: 1.4rem;
    font-weight: bold;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  }
</style>
