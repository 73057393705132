/**
  *
  * CandidateHelper.ts
  *
  * Desc: Application. Helper for candidate (contact infos to get good format)
  *
  * Author : Sebastien Hoarau
  *
  * Project : cocolake
  *
  */


// Models
import type { Country } from '../domain/types/Country';

// Constants
import { countries } from '../../../constants/countries';

// Utils
import type { CountryCode } from 'libphonenumber-js';


/**
 * function to get country code to select at startup
 * @param countries 
 * @returns 
 */
export function getCountryCode(): string {
  let countryCode: string = countries[0].value;

  const countryBU = sessionStorage.getItem('countryBU');

  if (countryBU && countryBU !== 'null') {
    countryCode = (countries.find(country => country.code === countryBU) as Country).value;
  }

  return countryCode;
}

export function getISOCode(valueCode: string): CountryCode {
  let ISOCode: string = countries.find(country => country.value === valueCode)?.code as string;
  return ISOCode as CountryCode;
}

/**
 * function to get good format of phone number and take 9 or 10 number depending on the format
 * @param phoneNumber 
 * @returns 
 */
export function getGoodPhoneNumberFormat(phoneNumberClean: string) {
  if (phoneNumberClean) {

    // replace the first 00 to +
    if (phoneNumberClean.slice(0, 2) === '00') {
      phoneNumberClean = `+${phoneNumberClean.slice(2, phoneNumberClean.length)}`;
    }
    
    //remove special caracters 
    phoneNumberClean = phoneNumberClean.replaceAll(/[^\w]/g, '');

    // numbers to get on phone number
    let nbToGet: number = 9;

    // verificate if it's not already good format length
    if (phoneNumberClean.length > nbToGet) {
      if (phoneNumberClean[phoneNumberClean.length - nbToGet - 1] !== '0') {

        // verify if we found the country code phone number
        const i: number = countries.findIndex(country => country.value.replaceAll('+', '') === phoneNumberClean.slice(0, phoneNumberClean.length - nbToGet));

        if (i === -1) {
          nbToGet = 10;
        }
      }
    }
    phoneNumberClean = phoneNumberClean.slice(phoneNumberClean.length - nbToGet);
  }

  return phoneNumberClean;
}