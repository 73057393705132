<script lang="ts">
  /**
   *
   * Header.svelte
   *
   * Desc : Lib. Header svelte
   *
   * Author : Dmytro Teliakovskyi
   *
   * Project : cocolake
   *
   */


  // Constants
  import { bertekLogo } from "../constants/images";

  // Components
  import TiArrowBack from "svelte-icons/ti/TiArrowBack.svelte";
  import MdExitToApp from "svelte-icons/md/MdExitToApp.svelte";
  import Logout from "../modules/authentication/presentation/page/Logout.svelte";

  // Utils
  import { Link } from "svelte-navigator";


  export let back = false;
  let isOpen = false;
</script>

<header>
  {#if back}
    <Link to="/">
      <div>
        <TiArrowBack />
      </div>
    </Link>
  {/if}
  <Link to="/">
    <div>
      <img src={bertekLogo} alt="Bertek logo" />
    </div>
  </Link>
  <div on:click={() => (isOpen = true)}>
    <MdExitToApp />
  </div>
</header>

<Logout bind:isOpen />

<style lang="scss">
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background: rgb(50, 54, 57);
    border-bottom: 1px solid white;
  }
  div {
    cursor: pointer;
    display: block;
    color: white;
    font-weight: 300;
    height: 3rem;
    width: 3rem;
    transition: all 0.2s;

    &:hover {
      color: #37f489;
      animation: jelly 0.5s 1;
    }
  }
  img {
    display: block;
    height: 2rem;
  }

  @keyframes jelly {
    from,
    to {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    25% {
      -webkit-transform: scale(0.95, 1.01);
      transform: scale(0.95, 1.01);
    }
    50% {
      -webkit-transform: scale(1.01, 0.95);
      transform: scale(1.01, 0.95);
    }
    75% {
      -webkit-transform: scale(0.99, 1.09);
      transform: scale(0.99, 1.01);
    }
  }
</style>
