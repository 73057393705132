/**
  *
  * MsalConfig.ts
  *
  * Desc: Config. for microsoft authentication
  *
  * Author : Sebastien Hoarau
  *
  * Project : cocolake
  *
  */


// Utils
import type Msal from '@azure/msal-browser';
 

export const msalConfig = {
  auth: {
    clientId: global.APP_CLIENT_ID as string,
    authority: global.APP_LOGIN_LINK as string,
    redirectUri: global.APP_REDIRECT_URI as string,
    postLogoutRedirectUri: global.APP_REDIRECT_URI as string,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export function logoutRequest(msalServiceReq : Msal.PublicClientApplication) {
  return { 
    account: msalServiceReq.getActiveAccount(), 
    mainWindowRedirectUri: global.APP_REDIRECT_URI as string 
  };
};
