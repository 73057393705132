<script>
  /**
  *
  * Authentication.svelte
  *
  * Desc: Page. for authentication
  *
  * Author : Dmytro Teliakovskyi, Sebastien Hoarau
  *
  * Project : cocolake
  *
  */


  // Application
  import { logout } from '../../application/UserController';

  // Components
  import Modal from '../../../../lib/Modal.svelte';
  import Button from '../../../../lib/Button.svelte';
  

  export let isOpen = false;

  const disconnection = async () => {
    await logout();
  };
</script>

<Modal bind:open={isOpen}>
  <main>
    <h2>Are you sure you want to log out?</h2>
    <footer>
      <Button type={'cancel'} on:click={() => (isOpen = false)}>No</Button>
      <Button on:click={disconnection}>Yes</Button>
    </footer>
  </main>
</Modal>

<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
  }

  h2 {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    font-weight: 300;
    white-space: nowrap;
    color: white;
    background-color: rgb(82, 86, 89);
  }

  footer {
    padding: 2rem 4rem;
    background: rgb(50, 54, 57);
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    border-top: 1px solid white;
  }
</style>
