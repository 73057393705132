/**
  *
  * UserController.ts
  *
  * Desc: Application. Controller for microsoft authentication
  *
  * Author : Sebastien Hoarau
  *
  * Project : cocolake
  *
  */


// Repositories
import { msalRepository } from '../infrastructure/MsalRepository';

// Models
import { UserService } from '../domain/services/UserService';


const msalService = new UserService(msalRepository);

/**
 * function login into microsoft services
 */
export async function login() {
  await msalService.login();
}

/**
 * logout from microsoft services
 */
export async function logout() {
  await msalService.logout();
}

/**
 * get active account user name
 */
export function getUserName(): string {
  const userName = msalService.getUserName();
  return userName.split(' ')[0];
}

/**
 * get active email of user
 */
export function getEmail(): string {
  return msalService.getEmail();
}

/**
 * get refreshed token from microsoft services
 */
export async function getIdToken() {
  return msalService.getIdToken();
}
