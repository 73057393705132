/**
  *
  * MsalRepository.ts
  *
  * Desc: Repository. for microsoft authentication
  *
  * Author : Sebastien Hoarau
  *
  * Project : cocolake
  *
  */


// Models
import type { MsalRepositoryInterface } from '../domain/interfaces/MsalRepositoryInterface';

// Utils
import * as Msal from '@azure/msal-browser';

// Configs
import { msalConfig, loginRequest, logoutRequest } from './MsalConfig';

class MsalRepository implements MsalRepositoryInterface {
  private msalInstance = new Msal.PublicClientApplication(msalConfig);

  // TODO clear
  async login() {
    try {
      await this.msalInstance.loginPopup(loginRequest);
    } catch (err) {
      throw err;
    }
  }

  async getIdToken() {
    const authenticationResult: Msal.AuthenticationResult = await this.msalInstance.acquireTokenSilent({
      scopes: ['User.Read'],
      account: this.msalInstance.getAllAccounts()[0],
    });
    return authenticationResult.idToken;
  }

  getUserName() {
    return this.msalInstance.getAllAccounts()[0].name as string;
  }

  getEmail() {
    return this.msalInstance.getAllAccounts()[0].username as string
  }

  async logout() {
    try {
      sessionStorage.clear();
      await this.msalInstance.logoutRedirect(logoutRequest(this.msalInstance));
    } catch (error) {
      console.log(error);
    }
  }
}

export const msalRepository = new MsalRepository();
