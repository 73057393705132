/**
  *
  * UserCrmController.ts
  *
  * Desc: Application. Controller for CRM data
  *
  * Author : Sebastien Hoarau
  *
  * Project : cocolake
  *
  */


// Helpers
import ApiRepository from '../../../helpers/ApiRepository';


/**
 * Get Country Business Unit and store it in session storage
 */
export async function getCountryBU(email: string){
    const { data } = await ApiRepository.get(`/user?email=${email}`);
    sessionStorage.setItem('countryBU', data);
}