/**
 *
 * CVResultRepository.ts
 *
 * Desc : Infrastructure. Concrete implementation of Repo itf for CVResult
 *
 * Author : Dmytro Teliakovskyi, Nicolas Galois
 *
 * Project : cocolake
 *
 */


// Controllers
import { getEmail } from '../../../authentication/application/UserController';

// Helpers
import ApiRepository from '../../../../helpers/ApiRepository';

// Models
import type { CVResultRepositoryInterface } from '../../domain/interfaces/CVResultRepositoryInterface';
import type { CVResult } from '../../../../models/CVResult';
import type { CVQuery } from '../../../../models/CVQuery';


class CVResultRepository implements CVResultRepositoryInterface {
  /**
   * GET all cv from a query
   * @param query
   */
  async get(query: CVQuery): Promise<CVResult> {
    try {
      const { data } = await ApiRepository.get(`/cvs`, {
        keywords: query.keywords,
        threshold: query.threshold,
        distance: query.distance,
        city: query.city,
        country: query.country,
        type: query.type,
        email: getEmail()
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Get cv data representing the pdf by md5 id
   * @param id
   */
  async getDataById(id: string) {
    try {
      const { data } = await ApiRepository.get(`/cv/${id}`, {email: getEmail()});
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export const cvResultRepository = new CVResultRepository();
