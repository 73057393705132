/**
 *
 * CandidateRepository.ts
 *
 * Desc : Infrastructure. Concrete implementation of Repo itf for Candidate
 *
 * Author : Dmytro Teliakovskyi, Nicolas Galois
 *
 * Project : cocolake
 *
 */


// Helpers
import ApiRepository from '../../../../helpers/ApiRepository';

// Models
import type { CandidateRepositoryInterface } from '../../domain/interfaces/CandidateRepositoryInterface';
import type { CareerFields } from '../../domain/types/CareerFields';
import type {Candidate} from "../../domain/types/Candidate";


class CandidateFormRepository implements CandidateRepositoryInterface {

  async get(email: string, cvId: string): Promise<Candidate> {
    try {
      const {data} = await ApiRepository.get("/contact/" + cvId + "?email=" + email)
      return data
    } catch (e) {
      throw e
    }
  }

  /**
   * GET career fileds from CRM
   *
   *
   */
  async getFields(): Promise<CareerFields> {
    try {
      const { data } = await ApiRepository.get(`/careernames`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async create(candidate: Candidate, cvId: string, email: string, annotation: string): Promise<Candidate> {
    try {
      const { data } = await ApiRepository.post(`/contact/${cvId}?email=${email}`, {candidate, annotation});
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export const candidateFormRepository = new CandidateFormRepository();
