<script lang="ts">
  /**
   *
   * CandidateForm.svelte
   *
   * Desc : Components. Candidate form
   *
   * Author : Dmytro Teliakovskyi, Nicolas Galois, Sebastien Hoarau
   *
   * Project : cocolake
   *
   */


  // Models
  import type { CV } from "../../../../models/CV";
  import type { CareerFields } from "../../domain/types/CareerFields";
  import type { Candidate } from "../../domain/types/Candidate";

  // Constants
  import { countries } from "../../../../constants/countries";

  // Helpers
  import { createCandidate, getCandidate } from "../../application/CandidateController";
  import { getCountryCode, getGoodPhoneNumberFormat, getISOCode } from "../../application/CandidateHelper";

  // Components
  import Modal from "../../../../lib/Modal.svelte";
  import Button from "../../../../lib/Button.svelte";

  // Utils
  import { createEventDispatcher } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { isValidPhoneNumber } from "libphonenumber-js";
  import FaTimes from "svelte-icons/fa/FaTimes.svelte";
  import FaFileInvoice from "svelte-icons/fa/FaFileInvoice.svelte";
  import FaFileSignature from "svelte-icons/fa/FaFileSignature.svelte";

  
  export let cv: CV;
  export let careerFields: CareerFields;
  export let candidate: Candidate;
  export let isOpen = false;

  const dispatch = createEventDispatcher();

  let isLoading = false;
  let isCreated = false;
  let isGoesWrong = false;
  let countryCode = getCountryCode();
  let phoneNumber: string = getGoodPhoneNumberFormat(cv.phoneNumber);
  let contractId = careerFields.contractTypeList[0].id;

  function handleNavigate() {
    window.open(
      `${global.CRM_URL}main.aspx?` +
        `appid=${global.CRM_APP_ID}` +
        `&newWindow=true&pagetype=entityrecord` +
        `&etn=contact&id=${candidate.contactId}`,
      "_blank"
    );
  }

  function close() {
    dispatch("close");
  }

  function handleClick(contract: { id: string; label: string }) {
    contractId = contract.id;
  }

  async function verifyCandidate() {
    candidate = await getCandidate(cv.email, cv.id);
    if (candidate && candidate.contactId && !candidate.career) {
      isOpen = true;
    } else {
      handleSubmit();
    }
  }

  async function sendForm(data) {
    isLoading = true;

    const candidate: Candidate = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: `${data.countryCode}${data.phoneNumber}`,
      career: {
        type: data.careerId,
        contractType: data.contractId,
        careerName: careerFields.careerTypeList.find(
          (career) => career.id === data.careerId
        )?.label as string,
      },
    };

    try {
      const newCandidate = await createCandidate(candidate, cv.id, data.annotation);

      isCreated = true;
      cv.careerId = newCandidate.career.id;
      cv.isFoundOnCrm = true;
      isLoading = false;
      isGoesWrong = false;
    } catch (error) {
      isGoesWrong = true;
      isLoading = false;
    }
  }

  const { form, errors, handleChange, handleSubmit } = createForm({
    initialValues: {
      firstName: cv.firstName,
      lastName: cv.lastName,
      email: cv.email,
      phoneNumber: phoneNumber || "",
      country: countryCode,
      careerId: "",
      annotation: ""
    },
    validate: (values) => {
      let errors: any = {};

      if (!values.email) {
        errors["email"] = "Email is Required";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
        errors["email"] = "Invalid email address";
      }
      if (!values.careerId) {
        errors["careerId"] = "You must chose one of them";
      }
      if (!values.firstName) {
        errors["firstName"] = "You must provide name";
      } /*
      else if (!/^[A-Za-z]+$/.test(values.firstName)) {
        errors['firstName'] = 'Only characters are accepted!';
      }
      */
      if (!values.lastName) {
        errors["lastName"] = "You must provide lastname";
      }
      /*
      else if (!/^[\w]+$/.test(values.lastName)) {
        errors['lastName'] = 'Only characters are accepted!';
      }
       */
      if (!values.phoneNumber) {
        errors["phoneNumber"] = "You must provide phone number";
      } else if (!/^[1-9]{1}[0-9]*$/.test(values.phoneNumber)) {
        errors["phoneNumber"] = "Provide only numbers and started without 0";
      } else if (
        !isValidPhoneNumber(
          countryCode + values.phoneNumber,
          getISOCode(countryCode)
        )
      ) {
        errors["phoneNumber"] = "Bad format of phone number for this country";
      }

      if (phoneNumber) {
        if (
          !isValidPhoneNumber(
            countryCode + phoneNumber,
            getISOCode(countryCode)
          )
        ) {
          errors["country"] = "Bad format of phone number for this country";
        }
      }

      isOpen = false;
      return errors;
    },

    onSubmit: async (values) => {
      isOpen = false;
      await sendForm({ ...values, contractId, countryCode, phoneNumber });
    },
  });
</script>

{#if isLoading}
  <main class="wrapper main">
    <div class="spinner" />
  </main>
{:else if isCreated}
  <main class="wrapper main">
    <p>Candidate successfully created</p>
    <button
      on:click={() => {
        dispatch("close");
      }}>Close</button
    >
  </main>
{:else}
  <Modal bind:open={isOpen}>
    <main>
      <h2>Contact already in CRM</h2>
      <span
        >It looks like the candidate {candidate.firstName}
        {candidate.lastName} had already been created in the CRM</span
      >
      <span
        >You can check <h1 on:click={handleNavigate}>here</h1>
        the contact page to verify the information</span
      >
      <span>If everything is correct, you can create the career</span>
      <footer class="footer-modal">
        <Button type={"cancel"} on:click={() => (isOpen = false)}>Cancel</Button
        >
        <Button on:click={handleSubmit}>Create career</Button>
      </footer>
    </main>
  </Modal>
  <main class="main">
    <header>
      {#if isGoesWrong}
        <h5>Sorry, CRM is encountering an issue, try again in few minutes</h5>
      {/if}
      <div>
        <h4>Add this contact in CRM</h4>
        <i on:click={close}>
          <FaTimes />
        </i>
      </div>
      <div>Please fill the form to add this candidate to the CRM</div>
    </header>

    <form>
      <section>
        <p>1. Career information</p>
        <section>
          <article>
            <div>
              <label for="career">Career Name*</label>
              <div class="input-box">
                <!-- svelte-ignore a11y-no-onchange -->
                <select
                  class:valid={$errors.careerId}
                  contenteditable="true"
                  bind:value={$form.careerId}
                  id="careerId"
                  name="careerId"
                  on:change={handleChange}
                >
                  <option value="" disabled>Please select the career</option>
                  {#each careerFields.careerTypeList as career (career.id)}
                    <option value={career.id}>{career.label}</option>
                  {/each}
                </select>
                {#if $errors.careerId}
                  <small>{$errors.careerId}</small>
                {/if}
              </div>
            </div>
          </article>
          <article class="group">
            {#each careerFields.contractTypeList as contract (contract.id)}
              <div
                class="radio"
                class:picked={contractId === contract.id}
                on:click={() => handleClick(contract)}
              >
                {#if contract.label === "Freelance"}
                  <i class="icon">
                    <FaFileInvoice />
                  </i>
                {:else}
                  <i class="icon">
                    <FaFileSignature />
                  </i>
                {/if}
                <div>{contract.label}</div>
              </div>
            {/each}
          </article>
        </section>
      </section>
      <section>
        <p>2. Contact information</p>
        <article>
          <div>
            <label for="firstName">First Name*</label>
            <div class="input-box">
              <input
                class:valid={$errors.firstName}
                name="firstName"
                id="firstName"
                type="text"
                bind:value={$form.firstName}
                on:change={handleChange}
              />
              {#if $errors.firstName}
                <small>{$errors.firstName}</small>
              {/if}
            </div>
          </div>
          <div>
            <label for="lastName">Last Name*</label>
            <div class="input-box">
              <input
                class:valid={$errors.lastName}
                name="lastName"
                id="lastName"
                type="text"
                bind:value={$form.lastName}
                on:change={handleChange}
              />
              {#if $errors.lastName}
                <small>{$errors.lastName}</small>
              {/if}
            </div>
          </div>
          <div>
            <label for="email">Email*</label>
            <div class="input-box">
              <input
                class:valid={$errors.email}
                name="email"
                id="email"
                type="text"
                bind:value={$form.email}
                on:change={handleChange}
              />
              {#if $errors.email}
                <small>{$errors.email}</small>
              {/if}
            </div>
          </div>
          <div>
            <label for="phoneNumber">Business Phone*</label>
            <div class="input-box">
              <div
                class="phone"
                class:valid={$errors.phoneNumber || $errors.country}
              >
                <!-- svelte-ignore a11y-no-onchange -->
                <select
                  class="code"
                  contenteditable="true"
                  id="country"
                  name="country"
                  bind:value={countryCode}
                  on:change={handleChange}
                >
                  {#each countries as country}
                    <option value={country.value}>{country.flag}</option>
                  {/each}
                </select>
                <input
                  class="phone-input"
                  name="phoneNumber"
                  id="phoneNumber"
                  bind:value={phoneNumber}
                  on:change={handleChange}
                />
              </div>
              {#if $errors.phoneNumber || $errors.country}
                <small>{$errors.phoneNumber || $errors.country}</small>
              {/if}
            </div>
          </div>
        </article>
      </section>
      <section>
        <p>3. Other information</p>
        <article>
          <div>
            <label for="annotation">Note</label>
            <div class="input-box">
              <input
                name="annotation"
                id="annotation"
                type="text"
                bind:value={$form.annotation}
                on:change={handleChange}
              />
            </div>
          </div>
        </article>
      </section>
      <footer class="footer">
        <button on:click|preventDefault={verifyCandidate}>Submit</button>
      </footer>
    </form>
  </main>
{/if}

<style lang="scss">
  h1 {
    display: initial;
    cursor: pointer;
    color: #008fff;
    text-decoration: underline;
  }

  main {
    display: flex;
    flex-direction: column;
    background-color: rgb(82, 86, 89);
    text-align: center;
    color: white;
  }

  span {
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 1rem;
  }

  h2 {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    font-weight: 300;
    white-space: nowrap;
    color: white;
    background-color: rgb(82, 86, 89);
  }

  .footer-modal {
    margin-top: 2rem;
    padding: 2rem 4rem;
    background: rgb(50, 54, 57);
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    border-top: 1px solid white;
  }
  .main {
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    margin: 1rem 0;
    border-radius: 0.5rem;
    color: white;
    background: #525659;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  header {
    display: flex;
    flex-direction: column;
  }

  h4 {
    font-size: 1.6rem;
    justify-self: center;
  }

  h5 {
    color: #ff2d00;
    font-size: 1.6rem;
  }

  i {
    height: 1.8rem;
    width: 1.8rem;
    cursor: pointer;
    display: flex;
    justify-self: end;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  article {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  p {
    font-size: 1.5rem;
  }

  div {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
  }

  .input-box {
    flex: 0 0 65%;
    display: flex;
    flex-direction: column;
  }
  .group {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .radio {
    cursor: pointer;
    display: flex;
    gap: 1rem;
    border: 1px solid;
    border-radius: 0.5rem;
    padding: 1.4rem 3.2rem;
    transition: all 0.2s;
  }

  .icon {
    height: 3rem;
    width: 3rem;
  }

  .picked {
    border: 1px solid #37f489;
    background: #37f489;
    color: #323639;
  }

  small {
    display: inline-block;
    color: #ea140f;
    font-size: 1.4rem;
    font-weight: 400;
  }

  .code {
    border: none;
    flex: 0 0 30%;
    padding: 0;
  }

  select,
  input,
  .phone {
    // flex: 0 0 65%;
    padding: 0.6rem;
    border: 1px solid white;
    border-radius: 0.5rem;
  }
  .phone-input {
    border: none;
    padding: 0;
  }

  .valid {
    border: 1px solid #ea140f;
  }

  .wrapper {
    padding: 4rem 0;
    flex-direction: row;
    align-items: center;
  }
  .spinner {
    width: 4rem;
    height: 4rem;
    background: #37f489;
    border-radius: 3px;
    animation: animate 0.5s linear infinite;
  }

  @keyframes animate {
    17% {
      border-bottom-right-radius: 3px;
    }
    25% {
      transform: translateY(9px) rotate(22.5deg);
    }
    50% {
      transform: translateY(18px) scale(1, 0.9) rotate(45deg);
      border-bottom-right-radius: 4rem;
    }
    75% {
      transform: translateY(9px) rotate(67.5deg);
    }
    100% {
      transform: translateY(0) rotate(90deg);
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }

  button {
    padding: 1rem;
    cursor: pointer;
    font-size: 1.6rem;
    background: #323639;
    border-radius: 0.5rem;
    color: white;
    transition: all 0.2s;

    &:hover {
      background: #37f489;
      animation: jelly 1s;
    }

    @keyframes jelly {
      from,
      to {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
      }
      25% {
        -webkit-transform: scale(0.95, 1.01);
        transform: scale(0.95, 1.01);
      }
      50% {
        -webkit-transform: scale(1.01, 0.95);
        transform: scale(1.01, 0.95);
      }
      75% {
        -webkit-transform: scale(0.99, 1.09);
        transform: scale(0.99, 1.01);
      }
    }
  }
</style>
