<script lang="ts">
  /**
   *
   * SavedSearch.svelte
   *
   * Desc : Component. To display saved searches
   *
   * Author : Dmytro Teliakovskyi, Nicolas Galois, Sebastien Hoarau
   *
   * Project : Cocolake
   *
   */


  // Applications
  import SavedSearchController from '../../application/SavedSearchController';

  // Models
  import type { SavedSearch } from '../../domain/types/SavedSearch';

  // Stores
  import { savedSearchStore } from '../../infrastructure/store/SavedSearchStore';

  // Components
  import FaTimes from 'svelte-icons/fa/FaTimes.svelte';

  // Utils
  import { navigate } from 'svelte-navigator';
  import { onMount } from 'svelte';
  

  const handleNavigate = (url: SavedSearch) => {

    if (!url.location) {
      navigate(`/result/search=${url.keywords}&type=keywords&id=${url.id}`);
    } else if(url.location && url.location.country && url.location.city) {
      navigate(`/result/search=${url.keywords}&country=${url.location.country}&city=${url.location.city}&distance=${url.location.distance}&type=keywords&id=${url.id}`);
    } else {
      navigate(`/result/search=${url.keywords}&country=${url.location.country}&type=keywords&id=${url.id}`);
    }
  };

  const handleDelete = (url: SavedSearch) => {
    SavedSearchController.delete(url);
  };

  const handleUpdate = async (search: SavedSearch) => {
    await SavedSearchController.updateNotifications(search.id as string, search.wantNotifications as boolean);
  };

  const parseNumber = (nb) => {
    const nbNewResult : number = nb as number;

    return nbNewResult;
  }

  const nbResultDisplaying = (nb) => {
    const nbNewResult = parseNumber(nb);
    let message : string = "";

    if(nbNewResult > 10) {
      message = "More than 10 news CVs !";
    } 
    else if (nbNewResult >= 1){
      message = `You have ${nbNewResult} new(s) CV(s) !`;
    }

    return message;
  }

  onMount(async () => {
    await SavedSearchController.getAll();
    
  });

  // TODO limiter les dépendance au store dans la vue ?
  // remplacer le if par un await de getAll => qui renvoie les urls favs
</script>

{#if $savedSearchStore.length}
  <section>
    <h4>Favorite searches</h4>
    <main>
      {#each $savedSearchStore as url}
        <div>
          {#if parseNumber(url.nbNewResult) > 0}
          <i class="left" style="color:red;">{nbResultDisplaying(url.nbNewResult)}</i>
          {/if}
          <i class="cross" on:click={() => handleDelete(url)}>
            <FaTimes />
          </i>
          <span>
            Notifications : 
            <input bind:checked={url.wantNotifications} on:click={() => {url.wantNotifications = !url.wantNotifications; handleUpdate(url);}} type=checkbox  >
          </span>
          <p on:click={() => handleNavigate(url)}>
            <span>
              {#if url.keywords}
                {url.keywords}
              {/if}
            </span>
            {#if url.location}
              {#if url.location.city}
                <span>{`${url.location.city}, ${url.location.country}`}</span>
                <span>{`radius of ${url.location.distance} kms`}</span>
              {:else}
                <span>{`${url.location.country}`}</span>
              {/if}
            {/if}
          </p>
        </div>
      {/each}
    </main>
  </section>
{/if}

<style lang="scss">
  section {
    margin-top: 6rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    color: white;
  }
  .left {
    text-align: left;
  }

  main {
    max-width: 80vw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    gap: 2rem;

    @media only screen and (max-width: 37.5em) {
      max-width: 60vw;
    }
  }

  h4 {
    text-align: center;
    font-weight: 500;
    font-size: 1.8rem;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(50, 54, 57);
    border-radius: 1rem;
    transition: all 0.2s;
    &:hover {
      background: #37f489;
    }
  }

  .cross {
    height: 1.8rem;
    width: 1.8rem;
    cursor: pointer;
    align-self: flex-end;
    display: flex;
    margin: 1rem 1rem 0 0;
  }

  p {
    padding: 0 2rem 1rem 2rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.6rem;
  }
</style>
