<script lang="ts">
    /**
     *
     * CVDisplay.svelte
     *
     * Desc : Page. To display just One CV
     *
     * Author : Dmytro Teliakovskyi, Nicolas Galois
     *
     * Project : cocolake
     *
     */

    
    // Applications
    import { login } from "../../../authentication/application/UserController";
    import { getCVDataById } from "../../application/ResultController";
    
    // Components
    import Spinner from "../../../../lib/Spinner.svelte";
    import Header from "../../../../lib/Header.svelte";

    // Utils
    import { onMount } from "svelte";
    
    

    let data: string;
    let displayHeader: boolean = false;
    let url: string;

    onMount(async () => {
        try {
            await login();
            displayHeader = true;
            await getData(window.location.href.split("/")[4]);
            await b64toBlob();
        } catch (error) {
            console.log(error);
        }
    });

    async function getData(id: string) {
        try {
            const dataBase64 = await getCVDataById(
                window.location.href.split("/")[4]
            );
            data = dataBase64;
        } catch (error) {
            console.log(error);
        }
    }

    const b64toBlob = async () => {
        let urlB = `data:application/pdf;base64,${data}`;
        let res = await fetch(urlB);
        let blob = await res.blob();
        url = window.URL.createObjectURL(blob);
    }
</script>

{#if displayHeader}
    <Header />
{/if}
{#if data}
    <object
        title="currentCV"
        type="application/pdf"
        data="{url}"
    />
{:else}
    <Spinner />
{/if}

<style lang="scss">
    object {
        flex: 1;
        height: calc(100vh - 6.6rem);
        width: 100%;
    }
</style>
