/**
 *
 * timeRangeSearch.ts
 *
 * Desc : Constant. Time ranges for search
 *
 * Author : Joan Clavel
 *
 * Project : cocolake
 *
 */

function today(offsetting: number = 0) {
    let date = new Date();
    date.setDate(date.getDate() + offsetting);
    date.setHours(0, 0, 0, 0);
    return date;
};

export const timeRangesSearch = [
    { label: "Today", value: "today", endDate: () => today() },
    { label: "Since yesterday", value: "yesterday", endDate: () => today(-1) },
    { label: "Last week", value: "week", endDate: () => today(-7) },
    { label: "Last month", value: "month", endDate: () => today(-30) },
    { label: "Last 90 days", value: "quarter", endDate: () => today(-90) },
    { label: "Personalized", value: "custom", endDate: () => new Date(1970, 1, 1)},
    { label: "All", value: "all", endDate: () => new Date(1970, 1, 1) }
]
