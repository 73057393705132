/**
 *
 * AutocompleteRepository.ts
 *
 * Desc : Repository. Api to have autocomplete of city
 *
 * Author : Sebastien Hoarau, Nicolas Galois
 *
 * Project : Cocolake
 *
 */


// Models
import type { AutoCompleteResult } from '../../domain/types/AutoCompleteResult';

// Utils
import axios from 'axios';

class AutocompleteRepository {
  async find(keyword: string): Promise<AutoCompleteResult[]> {
    const url =
      `${global.GEO_URL_API}?` +
      `apiKey=${global.GEO_KEY_API}&` +
      `language=en&` +
      `q=` +
      encodeURIComponent(keyword) +
      ``;
    const { data } = await axios.get(url);

    return data.items;
  }
}

export const autocompleteRepository = new AutocompleteRepository();